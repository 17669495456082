import React, { ReactElement } from "react";
import { graphql } from "gatsby";
import {
  GatsbyPageProps,
  FixedSharpImageFile,
  AllFileQueryResult,
} from "@ymcansw-camping/common";
import TestimonialBlock, { Testimonial } from "../components/testimonial-block";
import GeneralPageLayout from "../components/general-page-layout";

type TestimonialsProps = GatsbyPageProps<
  AllFileQueryResult<Testimonial> & {
    readonly bannerHeaderImage: FixedSharpImageFile;
  }
>;

export default function TestimonialsPage({
  data,
}: TestimonialsProps): ReactElement {
  const testimonials = data.allFile.nodes
    .map((node) => ({ id: node.id, ...node.childMarkdownRemark.frontmatter }))
    .filter((t) => t.published);
  return (
    <GeneralPageLayout
      meta={{
        title: "Testimonials",
        description: "See what people are saying about Camp Yarramundi.",
      }}
      bannerHeaderImage={data.bannerHeaderImage}
    >
      <div className="content-block">
        <h2>What people are saying</h2>
      </div>

      <div className="content-block review-page">
        {testimonials.map((testimonial) => (
          <TestimonialBlock key={testimonial.id} testimonial={testimonial} />
        ))}
      </div>
    </GeneralPageLayout>
  );
}

export const query = graphql`
  query TestimonialsQuery {
    bannerHeaderImage: file(relativePath: { eq: "img/testimonial-bg.jpg" }) {
      ...GeneralPageLayout_bannerHeaderImage
    }
    allFile(filter: { relativeDirectory: { eq: "testimonials" } }) {
      nodes {
        id
        childMarkdownRemark {
          frontmatter {
            ...TestimonialBlock_testimonial
          }
        }
      }
    }
  }
`;
