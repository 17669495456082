import React, { ReactElement } from "react";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import { MarkdownContent, FixedSharpImageFile } from "@ymcansw-camping/common";

export type Testimonial = {
  readonly name: string;
  readonly role?: string;
  readonly image?: FixedSharpImageFile;
  readonly description: string;
  readonly published: boolean;
};

type TestimonialBlockProps = {
  readonly testimonial: Testimonial;
};

export default function TestimonialBlock({
  testimonial: { image, name, role, description },
}: TestimonialBlockProps): ReactElement {
  const { defaultImage } = useStaticQuery(graphql`
    query TestimonialBlockQuery {
      defaultImage: file(relativePath: { eq: "img/testimonial-avatar.jpeg" }) {
        childImageSharp {
          fixed(width: 60, height: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <div className="review-block">
      <div className="profile-image">
        <Img fixed={(image || defaultImage).childImageSharp.fixed} alt={name} />
      </div>
      <h2 className="name h3">
        {name}
        {role && (
          <>
            {" "}
            <span className="dash">-</span>{" "}
            <span className="rb-title">{role}</span>
          </>
        )}
      </h2>
      <MarkdownContent>{description}</MarkdownContent>
    </div>
  );
}

export const query = graphql`
  fragment TestimonialBlock_testimonial on MarkdownRemarkFrontmatter {
    name
    role
    image {
      childImageSharp {
        fixed(width: 60, height: 60) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    description
    published
  }
`;
